#toast-container {
    position: fixed;
    z-index: 999999;
    pointer-events: none;
    padding: 1.25rem;
    width: 100%;
}

#toast-container[class*=-center] .toastr {
    margin-left: auto;
    margin-right: auto;
}

#toast-container[class*=-left] .toastr {
    margin-right: auto;
}

#toast-container[class*=-right] .toastr {
    margin-left: auto;
}

#toast-container[class*=-full] .toastr {
    width: 100%;
}

#toast-container[class*=toast-top] {
    top: 0;
}

#toast-container[class*=toast-bottom] {
    bottom: 0;
}

.toastr {
    position: relative;
    pointer-events: auto;
    background: #fff;
    box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15);
    border-radius: 4px;
    opacity: 1;
    border-left: 4px solid transparent;
    padding: 1.25rem 4.5rem 1.25rem 1.25rem;
    width: 430px;
    max-width: 100%;
    font-size: 0.875rem;
    line-height: 1.3rem;
}

.is-dark .toastr {
    background: #101924;
    color: #fff;
}

.toastr:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toastr.toast-success {
    border-color: #1ee0ac;
}

.toastr.toast-success .icon {
    color: #1ee0ac;
}

.toastr.toast-error {
    border-color: #e85347;
}

.toastr.toast-error .icon {
    color: #e85347;
}

.toastr.toast-info {
    border-color: #09c2de;
}

.toastr.toast-info .icon {
    color: #09c2de;
}

.toastr.toast-warning {
    border-color: #f4bd0e;
}

.toastr.toast-warning .icon {
    color: #f4bd0e;
}

.toastr-icon {
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    line-height: 1;
}

.toastr-icon + .toastr-text {
    padding-left: 2.35rem;
}

.toastr h4, .toastr .h4, .toastr h5, .toastr .h5, .toastr h6, .toastr .h6 {
    font-size: 1rem;
}

.is-dark .toastr h4, .is-dark .toastr .h4, .is-dark .toastr h5, .is-dark .toastr .h5, .is-dark .toastr h6, .is-dark .toastr .h6 {
    color: #fff;
}

.toast-close-button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    height: 32px;
    width: 32px;
    text-indent: -9999px;
    cursor: pointer;
}

.toast-close-button:after {
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
    color: #8094ae;
    font-family: "FontAwesome";
    content: "\f00d";
    font-size: 18px;
    line-height: 32px;
    width: 100%;
    text-align: center;
}

.is-dark .toast-close-button:after {
    color: #dbdfea;
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    filter: alpha(opacity=40);
}

.toast-title {
    font-weight: bold;
}

.toast-message {
    word-wrap: break-word;
    color: #526484;
}

.toast-message a, .toast-message label {
    color: #526484;
}

.toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
}